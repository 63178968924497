$bordure: 20px;

.root {

    position: relative;
    min-height: 100vh;

}

footer, .footer {

    display: grid;
    grid-template-columns: 120px calc((100% - 445px) / 100 * 70) calc((100% - 445px) / 100 * 30) 65px 260px;

    width: calc(100% - 20px);
    position: fixed;
    bottom: 0px;
    // top: calc(100vh - 77px);
    opacity: 1;

    height: 56px;
    overflow: hidden;

    padding: 10px 10px;
    border-top: 1px solid var(--gris);
    background-color: var(--blanc);

    transition: border $time $ease,
                background-color $time $ease,
                opacity 0.3 ease;
    
    @include small {

        grid-template-columns: 120px calc((100% - 380px) / 100 * 70) calc((100% - 380px) / 100 * 30) 260px;
        overflow: visible;
        // top: calc(100vh - 50px);

    }

    @include small {

        grid-template-columns: 60px calc(100% - 215px) 155px;
        overflow: visible;
        height: 29px;

    }


    img {

        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;

    }

    &_item {

        height: 56px;
        overflow: hidden;

        @include small {

            height: 40px;
            font-size: var(--med_font);

        }

    }

    .logo {

        margin: 0px 30px;
        margin-left: 20px;
        height: 56px;

        @include small {

            margin: 0px;
            height: 34px;
            position: relative;
            top: -2px;

        }

        svg {  

            height: 100%;

            path {

                fill: var(--noir);

                transition: fill $time $ease,

            }

        }

    }

    .text_agence {

        margin-right: 30px;
        color: var(--noir);
        max-width: 714px;

        transition: color $time $ease;

        &.white {

            color: var(--blanc);

            .black {

                color: var(--noir)!important;
    
            }

        }

        span {

            transition: color $time $ease;

            .black {

                color: var(--noir)!important;
    
            }
    
        }

        @include small {

            display: none;

        }
    }

    .navigation {

        font-size: var(--big_font);
        line-height: 1;
        overflow: visible;
        margin-left: 30px;

        @include small {

            font-size: var(--med_font);
            overflow: hidden;
            margin-left: 60px;
        }

        @include mobile {

            margin-left: 0px;

        }


        &_item {

            transition: color $time $ease;
            cursor: pointer;
            color: var(--noir);

            a {

                color: var(--noir);
                transition: color $time $ease; 

            }

        }

        &_item:hover, &_item.select {

            color: #FF0000;
            // animation: 0.7s $Power1EaseOut alternate left;

            a {

                color: #FF0000;

            }

        }

    }

    .slider_color {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 0.85rem;
        color: var(--noir);
        cursor: pointer;

        svg {

            width: 60%;

        }

        g {

            transition: fill 0.3s ease,
                        stroke 0.3s ease,
                        transform 0.3s ease;
        }

        .title_color::after {

            content: 'light mode'
    
        }

        #Ellipse_424 {

            transform: translate(0px, 0px);
            transition: transform 0.5s $Power4EaseInOut;

        }

        .title_color {

           margin-top: 10px; 

        }

        @include small {

            display: none;

        }

    }

    .logo_large {

        font-size: var(--big_font);
        text-align: center;
        line-height: 1;
        padding-left: 11px;

        @include small {

            font-size: var(--med_font);
            display: flex;
            justify-content: flex-end;

        }

        .sml {

            vertical-align: top;
            font-size: 0.6rem;
        
        }

        @include small {

            padding-left: 0px;

        }

    }

    .third_part {

        position: relative;

    }

    .info_project {

        font-size: var(--big_font);
        color: var(--noir);
        line-height: 1;

        display: block;
        position: absolute;
        top: 0px;
        left: 0px;

        opacity: 0;
        pointer-events: none;

        @include small {

            font-size: var(--med_font);
            margin-left: 60px;

        }

        &_date {

            display: flex;

            &_arrow {

                opacity: 1;
                
                transition: opacity 0.3s ease,
                            transform 0.3s ease;
                
                &.anim {

                    animation: 0.7s $Power1EaseOut alternate right;

                }

                &.hide{

                    opacity: 0;
                    transform: translateX(-20px);
    
                }

            }

            .arrow {

                width: 32px;
                margin: 0px 10px;

                polygon { fill: var(--rouge)}

            }

        }

    }

    &.absolute {

        position: absolute;
        bottom: 0px;
    
    }

}

@keyframes right {

    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0px);
    }

}

@keyframes left {

    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0px);
    }

}

// .project_infos_visible {

//     .navigation {

//         display: none;

//     }

//     .info_project {

//         display: block;

//     }

// }

.dark_mode {

    #Ellipse_424 {

        transform: translate(23.5px, 0px)!important;

    }

    .title_color::after {

        content: 'dark mode'!important;
        color: var(--noir);

    }

}
