
.is-home {

    height: 0px;
    min-height: 0px;

    .root {

        height: 0px;
        min-height: 0px;

    }

}

.home {

    margin-bottom: 77px;

    @include small {

        margin-bottom: 58px;

    }

    .intro {

        height: 100vh;
        width: 100vw;
        background-color: red;
        border-bottom: 100vh solid transparent;
        position: relative;
        z-index: 1000000;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        background-repeat: no-repeat!important;
        background-size: cover!important;
        background-position: center!important;

        transition: opacity 0.3s ease;

        &_text {

            font-size: var(--med_font);
            padding: 70px 120px;

            @include mobile {

            padding: 40px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            }

            .sub {

                margin-left: 70px;

                @include mobile {

                    margin-left: 0px;

                }

            }

        }

        .logo {

            margin: 0px 30px;
            margin-left: 20px;
            height: 56px;
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 10000000;

            @include mobile {

                margin: 0px;
                height: 36px;

            }

            svg {

                height: 100%;

                path {

                    fill: var(--noir);
                    transition: fill $time $ease,

                }

            }

        }

        .arrow_long {

            display: flex;
            position: absolute;
            width: 100vw;
            bottom: 2px;
            left: 0px;
            justify-content: center;

            // transition: transform 0.3s ease,
            //             opacity 0.3s ease;
            
            animation: 0.7s $Power1EaseOut alternate up;

            svg {

                width: 30px;
                height: 56px;

            }
        }
    }

    @keyframes up {

        0%{
            transform: translateY(0px);
        }
        50%{
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0px);
        }
    
    }

    .gallery {

        width: 100vw;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        z-index: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-snap-type: y mandatory;
        height: calc(100vh - 77px);
        background-color: var(--blanc);

        @include small {

            height: calc(100vh - 50px);
    
        }

        .figure_wrapper {

            height: calc(100vh - 77px);
            cursor: pointer;
            scroll-snap-align: start;
            scroll-snap-stop: always;

            @include small {

                height: calc(100vh - 58px);

            }

            .background_image {
    
                width: 100%;
                height: 100%;
                object-fit: contain;         
    
            }

            .figure {

                height: 0px;
                overflow: hidden;

                will-change: height;
                transition: height 2s $Power4EaseOut 0.2s;

                img {

                    opacity: 1;
                    position: relative;
                    top: 60px;

                }

            }

        }

        .figure_wrapper.small {
    
            width: 53.5vw;

            .figure {

                width: 55vw;
                // display: flex;
                // justify-content: center;
                // align-items: center;

                .background_image {

                    background-repeat: no-repeat;
                    background-position: center;

                    width: calc(55vw - 26vw);
                    height: calc((100vh - 77px) - 26vh);
                    margin: 13vh 13vw;

                    @include small {

                        height: calc((100vh - 58px) - 26vh);
        
                    }

                }

            }
        }
    
        .figure_wrapper.large {
    
            width: 43.5vw;

            .figure {

                width: 45vw;
                display: flex;
                justify-content: left;

                .background_image {

                    background-position: center;
                    background-repeat: no-repeat;
                    
                    width: 45vw;
                    height: calc(100vh - 77px);
                    margin: 0px;

                    object-fit: contain;

                    @include small {

                        height: calc(100vh - 58px);
        
                    }
    
                }

            }
    
        }

        .figure.visualised {

            height: calc(100vh - 77px);

            @include small {

                height: calc(100vh - 58px);

            }

        }
    }
}

.gallery {

    @include mobile {

        flex-direction: column;
        flex-wrap: nowrap!important;
    }

    .figure_wrapper {

        @include mobile {

            width: 100vw!important;
            height: calc((100vw * var(--th)) / var(--tw))!important;

        }

    }

    .figure_wrapper.small {

        @include mobile {

            display: flex;
            justify-content: center;
            align-items: flex-start;
            
        }

        .figure {

            @include mobile {

                width: 80vw!important;
                height: calc((80vw * var(--th)) / var(--tw))!important;
                padding: calc((10vw * var(--th)) / var(--tw)) 0px!important;
                
            }

            .background_image {

                @include mobile {

                    width: 80vw!important;
                    height: calc((80vw * var(--th)) / var(--tw))!important;
                    margin: 0px!important;
        
                }

            }

        }


    }

    .figure_wrapper.large {

        .figure {

            @include mobile {

                width: 100vw!important;
                height: calc((100vw * var(--th)) / var(--tw))!important;
    
            }

            .background_image {

                @include mobile {

                    width: 100vw!important;
                    margin: 0px!important;
                    height: calc((100vw * var(--th)) / var(--tw))!important;
        
                }

            }

        }

    }

}

.intro_done .intro {

    display: none;

}