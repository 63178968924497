.projet {

    min-height: 100vh;
    padding-bottom: 77px;

    @include small {

        padding-bottom: 50px;

    }

    .figure {

        width: 100vw;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        .image_wrapper {

            height: 0px;
            overflow: hidden;

            will-change: height;
            transition: height 1s ease;

        }

        &.visualised .image_wrapper {

            height: 100%;

        }

        img {

            width: 100vw;

        }

        &.width, &.width_100 {

            height: calc((100vw * var(--th)) / var(--tw));

            video {


                height: 100vw;
                width: auto;            

            }
        }

        &.double {

            display: flex;
            height: calc((50vw * var(--th) / var(--tw)));

            @include mobile {

                flex-direction: column;
                height: calc((100vw * var(--th) / var(--tw))* 2);


            }

            .image_wrapper {

                width: 50vw;

                @include mobile {

                    width: 100vw;
                    height: calc((100vw * var(--th) / var(--tw)));
                    scroll-snap-align: start;
                    scroll-snap-stop: always;

                }

                img {

                    width: 50vw;
                    height: calc((50vw * var(--th) / var(--tw)));

                    @include mobile {

                        width: 100vw;
                        height: calc((100vw * var(--th) / var(--tw)));
        
                    }

                }

            }

        }

        &.height, &.height_80 {

            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;

            .image_wrapper {

                width: calc((80vh * var(--tw)) / var(--th));
                height: 80vh;
                max-width: 80vw;
                max-height: calc((80vw * var(--th)) / var(--tw));

                img {

                    width: calc((80vh * var(--tw)) / var(--th));
                    height: 80vh;
                    max-width: 80vw;
                    max-height: calc((80vw * var(--th)) / var(--tw));
    
                }

            }

            video {


                height: 80%;
                width: 100%;
                object-fit: contain;
            

            }

        }

    }

    .text_description {

        padding: 40px 80px;
        text-align: center;
        color: var(--noir);
        scroll-snap-align: start;
        scroll-snap-stop: always;

    }

}

.nav_project {

    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    color: var(--gris);

    transition: top 0.3s ease;

    @include mobile {



    }

    &_item  {

        padding: 40px 80px;
        font-size: var(--med_font);
        opacity: 1;

        display: flex;

        @include mobile {

            padding: 40px 20px;

        }

        &.no_link {

            opacity: 0.3;

        }

        a {

            color: var(--gris);

            @include mobile {

                height: 21px;
                display: flex;
                align-items: center;

            }
            
        }

        .arrow {

            width: 23px;
            padding: 0px 7px;

            position: relative;
            top: 2px;

            @include mobile {

                padding: 0px;
                top: 0px;
    
            }

            
            polygon {

                fill: var(--gris);

            }

        }

        &.button_next {

            .arrow {

                transform: translateY(2px);
    
                @include mobile {

                    padding-left: 7px;
                    transform: translateY(-2.8px);

                }

            }

            :hover {

                .arrow {
    
                    animation: arrowMoveRight 0.6s;
    
                }
    
            }

        }

        &.no_link {

            opacity: 0;

        }

        &.button_prev {

            &.no_link {

                @include mobile {

                    transform: translate(0px, -5px);
    
                }

            }

            .arrow {

                transform: rotate(180deg);

                @include mobile {

                    padding-left: 7px;
                    transform: rotate(180deg) translateY(3.5px);

                }

            }

            &:hover .arrow {

                animation: arrowMoveLeft 0.6s;

            }
        }

        @keyframes arrowMoveRight {
            0% {
                transform: translateX(0px);
            }
            50% {
                transform: translateX(10px);
            }
            100% {
                transform: translateX(0px);
            }
        }

        @keyframes arrowMoveLeft {
            0% {
                transform: rotate(180deg) translateX(0px);
            }
            50% {
                transform: rotate(180deg) translateX(10px);
            }
            100% {
                transform: rotate(180deg) translateX(0px);
            }
        }

    }

}

.lock {

    .nav_project {

        justify-content: center;
        
        .button_close a {

            color: #000000;

        }

    }

}
