/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

// $base-fonts-path : '/wp-content/themes/carrie/assets/fonts';
@font-face {
    font-family: 'neuf';
    src: url(https://www.inpracticestudio.com/assets/fonts/Calibre-Medium.otf),
        url(https://www.inpracticestudio.com/assets/fonts/Calibre-Medium.otf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'neuf';
    src: url(https://www.inpracticestudio.com/assets/fonts/Calibre-MediumItalic.otf),
        url(https://www.inpracticestudio.com/assets/fonts/Calibre-MediumItalic.otf);
    font-weight: 300;
    font-style: italic;
}
