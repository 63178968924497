/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */


html, body {

    font-family: 'neuf', sans-serif;
    font-size: var(--courant_font);
    line-height: 1.3;
    width: 100vw;
    letter-spacing: -0.25px;
    background-color: var(--blanc);
    color: var(--noir);
    min-height: 100vh;

    transition: color $time $ease,
                background-color $time $ease;

}

body {

    overflow-x: hidden;

}
a {

    cursor: pointer;
    color: var(--noir);
    text-decoration: none;
    transition: color $time $ease;

}

.snap {

    scroll-snap-type: y mandatory;

}

.not_mob {

    @include mobile {

        display: none!important;

    }

}

.mob {

    display: none;

    @include mobile {

        display: block;

    }

}

img {

    opacity: 0;
    transform: translateY(-60px);

    transition: transfom 0.3s ease,
                opacity 0.3s ease;

    &.visualised {

        opacity: 1;
        transform: translateY(0px);

    }

}